import axios from 'axios';

export class BotLogger {
  constructor() {}

  createEvent(subject, eventType, data) {
    const currentDate = new Date();
    return {
      data: data,
      eventTime: currentDate,
      eventType: eventType,
      subject: subject,
      version: '1.0'
    };
  }

  bind(fn, context) {
    return function() {
      fn.apply(context, arguments);
    };
  }

  publishEvent(event, chatBot) {
    const BASE_URL = chatBot.botConfig.loggingUrl;
    const data = JSON.stringify(event);
    const url = `${BASE_URL}/api/SentLogToEventGridFunction?code=${chatBot.botConfig.loggingSecretKey}`;
    axios.post(url, data);
  }
}
