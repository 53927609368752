import axios from 'axios';
import identity from 'lodash/identity';
import isObject from 'lodash/isObject';
import store from '@app/store';
import Vue from 'vue';

import { Notification } from 'element-ui';

const { VUE_APP_API_URL } = process.env;
const isIE = /*@cc_on!@*/ false || !!document.documentMode;
axios.defaults.headers.post['Content-Type'] = 'application/json';
const token = window.localStorage.token;

let instance = axios.create({
  baseURL: VUE_APP_API_URL,
  timeout: 40000,
  headers: {
    Authorization: token ? `Bearer ${token}` : null
  }
});

// Add a request interceptor
instance.interceptors.request.use(
  function(config) {
    // Make request not cached by adding random query (only needed for IE)
    if (isIE) {
      let unixString = '';
      if (config.url.indexOf('?') > -1) {
        unixString = `&unix="${new Date().getTime()}`;
      } else {
        unixString = `?unix="${new Date().getTime()}`;
      }
      config.url += unixString;
    }

    // Send Accept-Language
    const user = store.getters['auth/user'];
    const defaultLanguage = store.getters['settings/defaultLanguage'];

    if (user && user.language) {
      config.headers['Accept-Language'] = user.language;
    } else if (Vue.i18n.locale()) {
      config.headers['Accept-Language'] = Vue.i18n.locale();
    } else if (defaultLanguage) {
      config.headers['Accept-Language'] = defaultLanguage;
    }

    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (config) => {
    if (config.config) {
      const { method } = config.config;
      if (
        store.getters['auth/authenticated'] &&
        (method.toUpperCase() === 'PUT' || method.toUpperCase() === 'POST') &&
        config.config.url.indexOf('/profile/recreate-token') > 0
      ) {
        if (store.state.dashboard.data) {
          store.dispatch('dashboard/getDashboard');
        }
      }
    }
    return config;
  },
  (error) => {
    let response = error.response;

    if (error.code && error.code === 'ECONNABORTED') {
      const message = Vue.prototype.$t('notifications.timeout');
      Notification.error({
        type: 'error',
        message: message,
        position: 'bottom-right'
      });
      return Promise.reject({ error: message });
    }

    if (response && response.statusText === 'Account not activated.') {
      return Promise.reject('Account not activated.');
    }

    let redirect = '/auth/login';
    if (response.status === 401 || response.status === 403) {
      store.dispatch('auth/logout');
      window.location.href = redirect;
    }

    //Virus detection:
    if (
      response.status === 500 &&
      response.data &&
      response.data.errors &&
      response.data.errors[0] &&
      response.data.errors[0].includes('virus scanning')
    ) {
      // const message = Vue.prototype.$t('notifications.timeout');
      const message = "The file you've uploaded contains a virus!";
      setTimeout(() => {
        Notification.error({
          type: 'error',
          message,
          position: 'bottom-right'
        });
      }, 2000);
    }
    return Promise.reject(error);
  }
);

export default instance;
