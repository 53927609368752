import Vue from 'vue';
import Router from 'vue-router';
import publicRoutes from './public';
import store from '../store';
import chatbot from '../chatbot-ui-core';

const RouteWrapper = () => import('@shared/components/RouteWrapper.vue');
const CompanyPageContent = () => import('../views/company-page/CompanyPageContent.vue');
const CompanyPageOverview = () => import('../views/company-page/CompanyPageOverview.vue');
const Dashboard = () => import('../views/dashboard/Dashboard.vue');
const Introduction = () => import('../views/introduction/Introduction.vue');
const LegacyForm = () => import('../views/form/LegacyForm.vue');
const LearningPageContent = () => import('../views/learning-page/LearningPageContent.vue');
const Profile = () => import('../views/settings/Profile.vue');
const PulseCheckOverview = () => import('../views/pulse-check/PulseCheckOverview.vue');
const PulseCheckContent = () => import('../views/pulse-check/PulseCheckContent.vue');
const QuizOverview = () => import('../views/quiz/QuizOverview.vue');
const QuizContent = () => import('../views/quiz/QuizContent.vue');
const Tasks = () => import('../views/tasks/Tasks.vue');
const Form = () => import('../views/form/Form.vue');
const TwoFactorSetup = () => import('../views/settings/TwoFactorSetup.vue');
const DeleteAccount = () => import('../views/settings/DeleteAccount.vue');
const ErrorPage = () => import('@shared/views/404.vue');

Vue.use(Router);

const { VUE_APP_HIDE_CHATBOT } = process.env;

const routes = [
  {
    path: '/',
    redirect: '/dashboard',
    name: 'home',
    component: RouteWrapper,
    beforeEnter(to, from, next) {
      if (store.getters['auth/authenticated']) {
        return store.dispatch('dashboard/getDashboard').then(
          () => {
            store.dispatch('dashboard/getDashboardSections');
            store.dispatch('dashboard/getOnboardeeTerminologies');
            store
              .dispatch('tasks/getOnboardeeTasks')
              .then(() => store.commit('dashboard/addToTodolist', store.getters['tasks/onboardeeTasks']));

            if (!VUE_APP_HIDE_CHATBOT && store.getters['auth/chatbotEnabled']) {
              const user = store.getters['auth/user'];
              chatbot(store.getters['auth/userToken'], {
                language: user.language,
                userId: user.id,
                userName: user.fullName,
                isFirstTimeAccess: user.isFirstTimeAccess,
                userAvatarImage: user.imageURL,
                companyId: user.companyId,
                onboardeeId: user.onboardings[0] ? user.onboardings[0].id : '',
                templateId: user.onboardings[0] ? user.onboardings[0].templateId : ''
              });
            }

            next();
          },
          (err) => {
            store.dispatch('auth/logout');
            setTimeout(() => {
              next('/auth/login'); //error
            }, 400);
          }
        );
      }
      store.dispatch('auth/setRedirectPath', {
        path: to.path
      });
      next('/auth/login');
    },
    children: [
      {
        path: 'dashboard',
        name: 'dashboard',
        component: Dashboard,
        beforeEnter(to, from, next) {
          // Checks if coming from one of these routes to fetch onboardeeInformation (which contains progress)
          if (['pulseCheckContent', 'quizContent', 'form', 'legacyForm', 'introduction', 'tasks'].includes(from.name)) {
            store.dispatch('dashboard/getOnboardeeInformation').then(() => next());
          } else {
            next();
          }
        }
      },
      {
        path: 'profile',
        name: 'profile',
        component: Profile
      },
      {
        path: 'profile/two-factor-setup',
        name: 'twoFactorSetup',
        component: TwoFactorSetup
      },
      {
        path: 'profile/delete-account',
        name: 'deleteAccount',
        component: DeleteAccount
      },
      {
        path: 'introduction/:id',
        name: 'introduction',
        component: Introduction
      },
      {
        path: 'pulse-check/:id',
        name: 'pulseCheckOverview',
        component: PulseCheckOverview
      },
      {
        path: 'pulse-check/:overview/:id',
        name: 'pulseCheckContent',
        component: PulseCheckContent
      },
      {
        path: 'quiz/:id',
        name: 'quizOverview',
        component: QuizOverview
      },
      {
        path: 'quiz/:overview/:id',
        name: 'quizContent',
        component: QuizContent
      },
      {
        path: '/company-page/:id/',
        name: 'companyPageOverview',
        component: CompanyPageOverview
      },
      {
        path: '/company-page/:overview/:id',
        name: 'companyPageContent',
        component: CompanyPageContent
      },
      {
        path: '/form/:id',
        name: 'legacyForm',
        component: LegacyForm
      },
      {
        path: '/tasks',
        name: 'tasks',
        component: Tasks
      },
      {
        path: '/learning-page/:module/:id',
        name: 'learningPageContent',
        component: LearningPageContent
      },
      {
        path: '/learning-page/:module/:id/:formId',
        name: 'learningPageContent',
        component: LearningPageContent
      },
      {
        path: '/form/:moduleId/:learningpageId/:formId',
        name: 'form',
        component: Form
      }
    ]
  },
  ...publicRoutes,
  {
    path: '/index.html',
    redirect: '/'
  },
  {
    path: '*',
    component: ErrorPage
  }
];

const router = new Router({
  mode: 'history',
  linkActiveClass: 'open active',
  scrollBehavior: () => ({
    y: 0
  }),
  routes
});

router.beforeEach((to, from, next) => {
  // redirect URLS from previous (angular.js) app
  if (to.hash && to.hash.indexOf('#/') >= 0) {
    let path = to.hash
      .replace('#/', '')
      .replace('company-pages/', 'company-page/')
      .replace('settings', 'profile')
      .replace('recover-password', 'auth/forgot-password');
    next({ path, replace: true });
  }
  next();
});

export { routes };

export default router;
