export class BotStyler {
  constructor() {}

  styleTitleBarText(botConfig) {
    if (botConfig.widget.title) {
      document.getElementById('assistentName').textContent = botConfig.widget.title;
    }
    if (botConfig.widget.position) {
      document.getElementById('assistentDescription').textContent = botConfig.widget.position;
    }
  }

  styleTitleBarImage(botConfig) {
    // if (botConfig.widget.avatarUrl) {
    document.getElementById('assistentImage').src = botConfig.widget.avatarUrl
      ? botConfig.widget.avatarUrl
      : 'https://image.flaticon.com/icons/png/128/181/181549.png';
    // }
  }

  styleTitleBarColor(botConfig) {
    if (botConfig.widget.titleBarColor) {
      document.getElementById('botTitleBar').style.backgroundColor = botConfig.widget.titleBarColor;
    }
  }

  styleWidgetLogo(botConfig) {
    // if (botConfig.widget.iconUrl) {
    document.getElementById('botButton').src = botConfig.widget.iconUrl
      ? botConfig.widget.iconUrl
      : 'https://image.flaticon.com/icons/png/128/181/181549.png';
    // }
  }

  styleSendBoxText(botConfig) {
    if (botConfig.widget.inputPlaceholder) {
      const element = document.querySelector('#webchat div.main form input');
      element.placeholder = botConfig.widget.inputPlaceholder;
    }
  }

  styleChatBubble(botConfig) {
    let styleOptions = {};
    if (botConfig.bubble.botBackgroundColor) {
      styleOptions.bubbleBackground = botConfig.bubble.botBackgroundColor;
    }
    if (botConfig.bubble.userBackgroundColor) {
      styleOptions.bubbleFromUserBackground = botConfig.bubble.userBackgroundColor;
    }
    return styleOptions;
  }

  styleChatWindowText(botConfig) {
    let styleOptions = {};
    if (botConfig.bubble.botTextColor) {
      styleOptions.bubbleTextColor = botConfig.bubble.botTextColor;
    }
    if (botConfig.bubble.userTextColor) {
      styleOptions.bubbleFromUserTextColor = botConfig.bubble.userTextColor;
    }
    if (botConfig.widget.font) {
      styleOptions.primaryFont = botConfig.widget.font;
    }
    return styleOptions;
  }

  getStyleOptions(botConfig) {
    let styleOptions = {
      avatarSize: 40,
      botAvatarImage: botConfig.widget.avatarUrl,
      botAvatarInitials: 'T_',
      bubbleBorderRadius: 6,
      bubbleFromUserBorderRadius: 6,
      hideUploadButton: !botConfig.widget.uploadAttachments,
      userAvatarInitials: 'U'
    };
    if (botConfig.userAvatarImage) {
      styleOptions.userAvatarImage = botConfig.userAvatarImage;
    }
    styleOptions = Object.assign(styleOptions, this.styleChatWindowText(botConfig));
    styleOptions = Object.assign(styleOptions, this.styleChatBubble(botConfig));
    return styleOptions;
  }

  styleBadge(botConfig) {
    if (botConfig.bubble.botTextColor) {
      document.getElementById('botBadge').style.color = botConfig.bubble.botTextColor;
    }
    if (botConfig.bubble.botBackgroundColor) {
      document.getElementById('botBadge').style.backgroundColor = botConfig.bubble.botBackgroundColor;
    }
  }

  applyCustomStyles(botConfig) {
    this.styleTitleBarText(botConfig);
    this.styleTitleBarImage(botConfig);
    this.styleTitleBarColor(botConfig);
    this.styleSendBoxText(botConfig);
    this.styleWidgetLogo(botConfig);

    //20190710, using fixed style
    //this.styleBadge(botConfig);
  }
}
