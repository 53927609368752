import axios from 'axios';
const { VUE_APP_LOGGING_API_URL } = process.env;
export const reportErrorDataDog = ({ errorMsg, line = 0, source }) => {
  console.error(errorMsg);
  const token = window.localStorage.getItem('token');
  if (token && VUE_APP_LOGGING_API_URL) {
    axios.post(
      `${VUE_APP_LOGGING_API_URL}/Logs`,
      {
        appName: 'talmundo',
        appType: 'onboarding ui',
        severity: 'Debug',
        messageTemplate: 'js-error',
        messageTemplateValues: [window.location.href + ' ' + line + ' ' + errorMsg],
        ipAddress: '-',
        source: source
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      }
    );
  }
};
