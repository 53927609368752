// APP
import Vue from 'vue';

import store from './store';
import PortalVue from 'portal-vue';

// Import Shared
import '@shared/translations';
import '@shared/filters';
import '@shared/validate';
import '@shared/components';

import router from './router';
import './registerServiceWorker';
import VueSanitize from 'vue-sanitize';
import App from './App.vue';
import { ignoredElements } from './talentechImport';
import { reportErrorDataDog } from '@/utils/errorHandeling';

Vue.config.productionTip = process.env.NODE_ENV === 'production';

// Portal
Vue.use(PortalVue);

// XSS
let defaults = VueSanitize.defaults;
// Allow images/iframe and setting some limitation to the attributes, thus preventing XSS attacks but allowing img/iframes
defaults.allowedTags = [...defaults.allowedTags, ...['img', 'iframe']];
defaults.allowedAttributes['iframe'] = ['src', 'allowfullscreen', 'frameborder', 'height', 'width'];
if (defaults.allowedAttributes['a']) {
  defaults.allowedAttributes['a'] = [...defaults.allowedAttributes['a'], ...['class', 'data-width', 'data-height']];
}

Vue.use(VueSanitize, defaults);

Vue.config.ignoredElements = ignoredElements;
Vue.filter('stringify', (input) => JSON.stringify(input));
// HTTP
Vue.prototype.$http = require('./http').default;

// ERROR HANDELING
// Vue.config.errorHandler = function(err, vm, info) {
//   // handle all vue errors
//   reportErrorDataDog({ errorMsg: err, source: 'vue' });
// };
//
// window.onerror = function(message, file, line, col, error) {
//   // handle all js errors
//   reportErrorDataDog({ errorMsg: error.message, line, source: 'js' });
//   return false;
// };
//
// window.onunhandledrejection = (event) => {
//   // handle uncaught promises
//   reportErrorDataDog({ errorMsg: event.reason, source: 'js' });
// };

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app');
